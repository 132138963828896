:root {
    --background-color1: #f8f7f2;
    --background-color2: #4682b4;

    --color-font: #000;
    --color-font2: #000;

    --color-svg: #000;
    --color2-svg: #ffefdf;
}
