@import url("./color.css");

.main {
    margin-top: 110px;
}
.container_main {
    width: 1240px;
    height: 1633px;

    background-color: var(--background-color2);
    font-size: 30px;
    border-radius: 16px;
    margin: 0 auto;
}
