@import url("./color.css");
@import url("https://fonts.googleapis.com/css2?family=Wallpoet&display=swap");

.header {
    background-color: var(--background-color2);
    width: 1440px;
    height: 100px;
    border-radius: 0 0 16px 16px;
    position: fixed;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.iteams {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.iteam_logo {
    padding: 15px 0 0 65px;
}
.iteam_name {
    min-height: 100px;
    font-family: "Wallpoet", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 44px;
    text-transform: uppercase;
    flood-color: var(--color-font1);
}
.iteam_shop {
    padding: 15px 65px 0 0;
}
/* .iteam_shop:hover {
    fill: var(--color2-svg);
}
.iteam_shop:active {
    fill: var(--color2-svg);
} */
.shop {
    height: 70px;
    width: 70px;
    transition: fill 0.3s ease transform 0.5s ease;
    fill: var(--color-svg); /*почтаковий колір  */
    margin: 15px 65px 0 0;
}
.shop:hover,
.logo.hover {
    fill: var(--color2-svg);
}
.shop:active {
    transform: scale(1.2);
}
